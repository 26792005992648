import React, { useState, useEffect } from 'react';
import '../LoadingScreen.css';

function LoadingScreen() {
  const [attentionText, setAttentionText] = useState('');
  const [wiseText, setWiseText] = useState('');
  const [isAttentionTextComplete, setIsAttentionTextComplete] = useState(false);

  useEffect(() => {
    const typeAttentionText = (text: string, index: number) => {
      if (index < text.length) {
        setAttentionText(text.substring(0, index + 1));
        setTimeout(() => {
          typeAttentionText(text, index + 1);
        }, 100);
      } else {
        setTimeout(() => {
          setIsAttentionTextComplete(true);
        }, 1000);
      }
    };

    typeAttentionText('attention is all you need', 0);
  }, []);

  useEffect(() => {
    if (isAttentionTextComplete) {
      const typeWiseText = (text: string, index: number) => {
        if (index < text.length) {
          setWiseText(text.substring(0, index + 1));
          setTimeout(() => {
            typeWiseText(text, index + 1);
          }, 100);
        }
      };

      typeWiseText('wise ', 0);
    }
  }, [isAttentionTextComplete]);

  return (
    <div className="loading-screen">
      <div className="loading-text">
        <div className="rain">Y</div>
        <div className="rain">O</div>
        <div className="rain">N</div>
        <div className="rain">I</div>
        <div className="rain">S</div>
        <div className="rain">0</div>
        <br></br>
        <h2 className="typewriter">
        {wiseText}
        {attentionText}
        </h2>
      </div>
    </div>
  );
}

export default LoadingScreen;
