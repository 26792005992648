import React from "react";
import './Chip.css';

interface ChipProps {
  text: string;
}

const Chip: React.FC<ChipProps> = ({ text }) => {
    const [isHovered, setIsHovered] = React.useState(false);
  
    const handleMouseEnter = () => {
      setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
      setIsHovered(false);
    };
  
    const chipStyle = {
      // display: "inline-block",
      // padding: "8px 12px",
      // margin: "3px",
      // borderRadius: "5px",
      // background: "linear-gradient(to right, #ff00e0, #ffa600, #00ff48, #00ffe0, #00c5ff, #0041ff, #be00ff, #ff007c)",
      // boxShadow: !isHovered ? "0 0 10px 0px #cccc00 inset" : "0 0 0 3px #cccc00",
      // transition: "box-shadow 0.2s ease-in-out",
      // cursor: "pointer",
      // color: "black",
    };
  
    const hoverStyle = {
      // background: "linear-gradient(to right, #ff007c, #be00ff, #0041ff, #00c5ff, #00ffe0, #00ff48, #ffa600, #ff00e0)",
      // color: "white"
    };
  
    const backgroundStyle = {
      // background: "linear-gradient(to right, #ff00e0, #ffa600, #00ff48, #00ffe0, #00c5ff, #0041ff, #be00ff, #ff007c)",
      // color: "black",
    };
  
    return (
      <div className='chip'
        style={{ 
          ...chipStyle, 
          ...(isHovered ? hoverStyle : backgroundStyle) 
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {text}
      </div>
    );
  };
  

export default Chip;

