import React, { useState } from "react";
import Chip from "./Chip";
import Typeform from "@typeform/embed-react";
import { SliderButton } from '@typeform/embed-react'

export const Info = () => {
  const listStyles = `
  .list-disc {
    list-style-type: disc;
    list-style-position: outside;
    padding-left: 2rem;
  }
`;
  
  const styles = {
    backgroundColor: "#0d0d0d",
    color: "white",
    fontFamily: "Courier",
    display: "flex",
    justifyContent: "center",
    height: "100%",
    width: "100vw",
    h5: {
      padding: "10px 0px",
    }
  }

  const callToAction = {
    justifyContent:"center",
    display:"flex",
    margin: "40px"
  }

  const buttonStyle = {
    fontSize: "10px",
    padding: "10px",
    margin: "10px",
    width: "150px",
    height: "150px",
  };

  const [showForm, setShowForm] = useState(false);

  const handleReachInClick = () => {
    setShowForm(true);
  }


  return (
    <div style={styles}>
      <div className="text-container">
        <div className="header-container">
          <div style={{textAlign:"center"}}>
            <h1>YONIS0</h1><br></br>
            <p style={{ padding: '0px' }}> Raising venture capital is a game. <br></br>Yoniso helps technical crypto founders learn the rules and win.  </p>
          </div>

          <div style={{ ...callToAction }}>
            <SliderButton className="form-button" style={{ ...buttonStyle }}
              id="cjVz6Qbn"
              onReady={() => {
                console.log('yay form ready')
              }}
            >
              reach in
            </SliderButton>
          </div> 
        </div>


        <h2 style={{ }}>PROCESS</h2>
        <ul className="list-disc" style={{ paddingLeft: "2rem" }}>
          <li>Fine-tune your pitch and story </li>
          <li>Prepare you to answer questions </li>
          <li>Write an investment memo/deck</li>
          <li>Build and manage investor CRM  </li>
          <li>VC outreach, leveraging rolodex </li>
          <li>Project-manage pitch schedule </li>
          <li>Consult on round construction </li>
        </ul>
        <br></br>
        
        <h5 style={{ ...styles.h5 }}>MOTIVATION</h5>
        <p> As professional investors ourselves, we've seen how heavily VCs rely on pattern matching (i.e. founder traits, traction, vision, demand) when making investment decisions. <br></br><br></br>Yoniso takes joy in pulling back the curtain, explaining the true rules and coaching founders through the fundraising process. </p>

        <h5 style={{ ...styles.h5 }}>BACKGROUNDS</h5>
        <p> Here's a bundle of accolades the team has collected that you can pattern match with:</p>
          <div className="chip-container">
            <Chip text="Forbes 30u30" />
            <Chip text="Columbia University" />
            <Chip text="Google Research" />
            <Chip text="Web3 Fund ($10B+ AUM)" />
            <Chip text="Web2 Fund ($3B+ AUM)" />
          </div>
        <br></br>
        <h5 style={{ ...styles.h5 }}> YONIS0 </h5>
        <p> In Theravada Buddhism <i>Yoniso Manasikara</i> translates to <i>Wise Attention</i>. The tech industry propogates the meme that time is one's scarcest resource. In an age where time is stolen through hijacking attention, Yoniso invites you to consider that <i>wise</i> attention is perhaps the scarcest resource of all. </p>
          
        <h5 style={{ ...styles.h5 }}> VALUE EXCHANGE</h5>
        <p> Flat retainer paid at the beginning of engagement + success based compensation denominated in <code style={{color: "#cccc00", fontSize: "14px"}}>% of total_token_supply vested over 2 years</code>. </p>
      </div>
    </div>
  )
}

export default Info
