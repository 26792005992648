import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { StytchProvider } from "@stytch/react"
import { StytchUIClient } from "@stytch/vanilla-js"
import TokenAuthenticator from "./components/TokenAuthenticator"

const STYCH_PUBLIC_TOKEN = "public-token-test-a8e9c97c-5753-4642-9639-f312ff2c6c6d"

const stytch = new StytchUIClient(STYCH_PUBLIC_TOKEN)

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <StytchProvider stytch={stytch}>
    <TokenAuthenticator>
      <App />
    </TokenAuthenticator>
  </StytchProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
