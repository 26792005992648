import { useState, useEffect, useRef } from 'react';
import LoadingScreen from './components/LoadingScreen';
import Info from './components/Info';
import './App.css';

const DOUBLE_TAP_INTERVAL = 400; // in milliseconds

const App = () => {
  const [showLoadingScreen, setShowLoadingScreen] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [touchCount, setTouchCount] = useState(0);
  const doubleClickRef = useRef(false);

  useEffect(() => {
    if (showLoadingScreen) {
      setTimeout(() => {
        setShowInfo(true);
      }, 7000);
    }
  }, [showLoadingScreen]);

  const handleButtonClick = () => {
    setShowLoadingScreen(true);
  };

  const handleDoubleClick = () => {
    doubleClickRef.current = true;
    setShowInfo(true);
  };


  const handleTouchStart = (event: React.TouchEvent) => {
    const currentTime = new Date().getTime();
    if (tapCount >= 2) {
      if (currentTime - lastTapTime < DOUBLE_TAP_INTERVAL) {
        setShowInfo(true);
      }
      lastTapTime = currentTime;
    }
    tapCount += 1;
  };

  let tapCount = 0;
  let lastTapTime = 0;

  if (showLoadingScreen && !showInfo) {
    return <div onDoubleClick={handleDoubleClick} onTouchStart={handleTouchStart}>
        <LoadingScreen />
      </div>
  }

  if (showInfo) {
    return <Info />;
  }

  return (
    <div className="container">
      <div className="loading-screen">
        <div className="loading-text">
          <div className="rain">Y</div>
          <div className="rain">O</div>
          <div className="rain">N</div>
          <div className="rain">I</div>
          <div className="rain">S</div>
          <div className="rain">0</div>
          <br></br>
          <button className="enter-button" onClick={handleButtonClick}></button>
        </div>
      </div>
    </div>
  );
};

export default App;
